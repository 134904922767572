.Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 7dvh;
  margin-bottom: 2dvh;
  text-align: center;
  position: sticky;
  top: 1.4rem;
  background-color: #171717;
}

.HeadText {
  color: rgba(255, 255, 255, 0.94);
  font-size: 1rem;
  font-weight: 700;
}

.SubHeadText {
  margin: 0.2rem;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.6);
}

.HeadTextBtn {
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 6dvh;
  height: 6dvh;
  color: rgba(255, 255, 255, 0.94);
  background-color: #323232;
  border-radius: 4px;
  font-size: 1.2rem;
  font-weight: 700;
}

.HeadTextBtn:active {
  background-color: #949494;
}
