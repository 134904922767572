.App {
  width: 100dvw;
  max-width: 820px;
  min-height: 100dvh;
  background-color: #171717;
  padding: 1.4rem;
  color: rgba(255, 255, 255, 0.94);
}

.wordTranslateInput {
  color: rgba(255, 255, 255, 0.94);
  width: 100%;
  height: 5.5dvh;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  margin-bottom: 1dvh;
  background-color: #171717;
  border: 0.1rem solid rgba(255, 255, 255, 0.94);
  border-radius: 1dvh;
  outline: none;
  text-align: left;
}

.diaryInput {
  color: rgba(255, 255, 255, 0.94);
  width: 100%;
  height: 74dvh;
  padding: 0.6rem;
  background-color: #171717;
  border: 0.1rem solid rgba(255, 255, 255, 0.94);
  border-radius: 0.8dvh;
  outline: none;
  text-align: left;

  font-size: 1rem;
}
.diaryCreateBtn {
  width: 100%;
  height: 5.7dvh;
  margin-top: 1dvh;
  color: #171717;
  font-weight: 400;
  letter-spacing: -0.45px;
  font-size: 1rem;
}

.footerText {
  margin-top: 1dvh;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
}
